@import 'src/style/variables';

.loading-mask {
  @keyframes spinAnim {
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: rgba(200, 200, 200, 0.4);
  &.header-space {
    top: 63px;
  }
  .loading-mask-spinner {
    display: inline-block;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    animation: spinAnim 500ms linear infinite;
    border-width: 0.3em;
    border-style: solid;
    margin: auto;
    border-color: $secondary-color transparent $secondary-color transparent;
  }
}
