.checkbox-group{
    .ui.radio.checkbox{
        padding: 0 1em 1em 0;
    }
}

.checkbox-group-label{
    font-size: 1em;
    font-weight: bold;
    padding: 0 0 0.5em 0;
}