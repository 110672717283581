@import 'src/style/variables';

.initialize-plan {
  flex: 1;
  padding: 2em;
  overflow: visible;
  .ui.stretched.grid {
    .column {
      height: 100%;
      padding-left: 2em;
      padding-right: 2em;

      .initialize-plan-header {
        font-weight: normal;
      }
      .dropdown-group-label {
        padding: 1em;
      }
      .segment {
        border: 0;
        box-shadow: none;
      }
      .dropdown-group {
        padding-top: 2em;
      }
      .dropdown {
        display: flex;
        align-items: center;
        height: 2em;
        font-size: 1.2em;
        padding-left: 1em;
        padding-right: 1em;
        background-color: #f3f1f5;
        border-radius: 0.4em;
        .chevron {
          margin-left: auto;
          color: $primary-color;
        }
        .menu {
          width: 100%;
        }
      }
    }
  }
}
