.scope-options-popup {
  .scope-popover-container{
    max-height: 80vh;
    overflow: auto;
  }
    .ui.basic.scope-button-group {
        border: none;
        button.ui.button.scope-status-button {
          border: none;
          text-align: left;
          color: black;
      }
      .scope-status-icon {
        float: left;
        margin-right: 1.2em;
      }
      .scope-status-button.current {
        background-color: #e2e2e2 !important; // I am bad at css
        font-weight: 600;
      }
    }
}