@import './mixins';

$colors: (
  gray: #f2f2f2,
  red: #cc3333,
  teal: #00a89b,
);

:root {
  @include color-variant(primary-color, #00a89b);
  @include color-variant(secondary-color, #00829d);
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
  @include shade-variant('gray', #f2f2f2);
  @include opacity-variant('black', black);
  @include opacity-variant('white', white);
}