$app-font-family: "Open Sans", sans-serif !default;
$primary-color: #00a89b !default;
$secondary-color: #00829d !default;

$navbar-height: 40px !default;
$navbar-bg: #303035 !default;
$navbar-fg: #ffffff !default;
$navbar-brand-width: 8em !default;
$navbar-active-bg: $primary-color !default;
$navbar-hover-bg: $secondary-color !default;

// $sidebar-bg: #f5f5f5;
// $sidebar-item-active-bg: $primary-color !default;
// $sidebar-item-hover-bg: $secondary-color !default;
// $sidebar-width: 225px !default;
// $sidebar-minimized-width: 50px !default;
$logo: url("../s5-logo-white-simple.png") !default;

$import-google-fonts: false;
$font-family: $app-font-family;