@import "../../style/functions";

.perspective-center {
  text-align: center;
}

.perspective-select {
  $orange: #ff6a5a;
  $orange_light: fade-out($orange, 0.5);
  $orange_dark: darken($orange, 20);
  $border: 2px solid;
  $border_highlight_color: rgba(69, 182, 159, 0.5);
  $disabled_border: 2px solid $orange_light;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  border-color: $orange;
  .facet-link {
    text-align: center;
    margin: 1rem;
    color: $orange;
    span {
      border: $border;
      border-radius: 100%;
      margin: 1rem;
    }
    label {
      color: black;
    }
    .fa-stack {
      width: 2em;
    }
    &.disabled {
      color: $orange_light;
      cursor: not-allowed;
      &:hover {
        color: $orange_light;
        & label {
          text-decoration: none;
        }
      }
    }
  }
  .facet-link:hover {
    border-color: $orange_dark;
    color: $orange_dark;
    label {
      text-decoration: underline;
    }
  }
  .disabled-perspective {
    color: gray;
    text-align: center;
    cursor: not-allowed;
    margin: 1rem;
    span.disabled {
      border: DISABLED_BORDER;
      border-radius: 100%;
      color: $border_highlight_color;
      border-color: $orange_light;
    }
    label {
      color: grey;
      cursor: not-allowed;
    }
    i {
      color: $orange_light;
    }
    i:hover {
      color: $orange_light;
    }
    .fa-stack {
      width: 2em;
    }
  }
}
.brandLogoCentered {
  width: 200px;
  transform: translateX(-47.5%);
  position: absolute;
  top: 26%;
}
