
.comments {
  display: flex;
  flex-direction: column;
  height: 100%; // necessary for flex box to work correctly
  & .header-content {
    padding: 0 1em 0 1em;
    margin-bottom: 13.7px;
    min-width: 252px;
    h1{
      font-size: 1.75rem;
      font-weight: lighter;
      margin-right: 2rem;
      display: inline-block;
    }
  }
  & .list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 35px);
    & .paper {
      overflow-y: scroll;
    }
    & .controls {
      padding: 1rem;
      display: flex;
      justify-content: right;
      & form.entry {
        display: flex;
        width: 100%;
        flex-direction: column;
        & button {
          margin: 1rem;
          align-self: flex-end;
          background-color: #00a89b;
          color: white;
          & div{
            display: flex;
            align-items: center;
            
          & span{
            margin-left: 8px;
            margin-right: -4px;
            & i{
              font-size: 20px;
            }
          }
        }
        }
      }
    }
  }
}
