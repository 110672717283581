.sidebar {
  background-color: var(--gray);
  color: var(--gray-700);
  display: grid;
  padding: 1rem 0;
  overflow-y: auto;
  &.collapsed {
    max-width: 4rem;
    .sidebar {
      &-link-icon { margin-left: 1.5rem; }
      &-links-list, &-nav {
        width: 4rem;
      }
      &-item-text,
      &-link-text,
      &-remove-favorite,
      &-section-toggle::after, 
      &-section-toggle-text {
        display: none;
      }
      &-title {
        opacity: 0;
        word-break: keep-all;
        white-space: nowrap;
      }
    }
  }
  &.expanded {
    width: 18.75rem;
    min-width: 18.75rem;
    
    .sidebar {
      &-link {
        overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      }
    }
  }
  &-item {
    border-bottom: 1px solid var(--gray-100);
    padding: 0.25rem 0;
  }
  &-link {
    color: inherit;   
    display: block;
    padding: 1rem 0;
    
    &[aria-current=true] {
      background-color: var(--primary-color);
      color: white;
      &:focus, &:hover {
        background-color: var(--primary-color-darker); 
      }
    }
    &.favorite {
      &:focus + .sidebar-remove-favorite,
      &:hover + .sidebar-remove-favorite {
        background-color: var(--secondary-color);
        color: white;
      }
      &[aria-current=true] + .sidebar-remove-favorite {
        background-color: var(--primary-color);
        color: white;
        &:focus, &:hover { background-color: var(--primary-color-darker) };
      }
    }
    &-icon {
      margin: 0 1.5rem 0 3rem;
      text-align: center;
      width: 1.25em;
    }
    &-item {
      &:hover {
        background-color: var(--secondary-color);
        & .sidebar-tooltip {
          display: block;
        }
      }
    }
    &:focus,
    &:hover {
      background-color: var(--secondary-color);
      color: white;
    }
  }
  .favorites {
    .sidebar {
      &-item {
        border-bottom: 0;
        color: var(--teal);
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        i { margin-right: 1.5rem; }
      }
      &-link {
        &-item {
          display: grid;
          grid-template-columns: 1fr auto;
        }
      }
    }
  }
  &-nav {
    width: 100%;
  }
  &-toggle {
    align-self: end;
    background-color: transparent;
    border: 0 none;
    text-align: right;
    .icon-chevron-circle-left {
      fill: var(--gray-500);
      width: 2rem;
    }
    &.collapsed {
      text-align: center;
      .icon-chevron-circle-left {
        transform: rotate(180deg);
      }
    }
    &:focus, &:hover {
      .icon-chevron-circle-left {
        fill: var(--gray-700);
      }
    }
  }
  &-tooltip {
    display: none;
    left: 18.75rem;
    position: absolute;
    padding-bottom: 3.4em;
    transform: translateY(-50%);
    white-space: nowrap;
    width: 100%;
    word-break: keep-all;
    z-index: 100;
    &-text {
      background-color: white;
      border-radius: 1rem;
      filter: drop-shadow(0.25rem 0.25rem 0.5rem var(--black-25));
      padding: 0.5rem 1rem;
      position: relative;
      &::before {
        content: '';
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent; 
        border-right: 1.25rem solid white; 
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover {
      // So that the tooltip doesn't flicker from blocking hover on the underlying element
      display: block;
    }
  }
  &-remove-favorite {
    background-color: transparent;
    border: 0 none;
    padding: 0 1rem;
    &:focus,
    &:hover {
      background-color: var(--primary-color-darker);
      color: white;
    }
  }
  &-section {
    &-icon {
      margin: 0 1.5rem;
      text-align: center;
      width: 1.25em;
    }
    &-toggle {
      align-items: center;
      background-color: transparent;
      border: 0 none;
      display: flex;
      height: 3rem;
      padding: 0 2rem 0 0;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      &::after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-font-feature-settings: normal;
        content: "\f078";
        display: inline-block;
        font-family: "Font Awesome 5 Pro";
        font-feature-settings: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        left: calc(100% - 1.5rem);
        line-height: 1;
        position: absolute;
        text-rendering: auto;
      }
      &[aria-expanded=true] {
        color: var(--teal);
        &::after {
          content: "\f077";
        }
      }
      &:focus, &:hover {
        background-color: var(--black-10);
        color: var(--primary-color-darker);
      }
    }
  }
  &-title {
    font-size: 12px;
    color: #8c8c8c!important;
  
    font-weight: 700!important;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
  }
  hr{
    margin-top: -5px;
    border-top: 1px solid var(--gray-100);
  }
}