.rightcontainer {
  display: none;
  width: 35rem;
  top: 0px;
  z-index: 1;
  // No left padding, as it's covered by the right padding of .page
  padding: 1rem 1rem 1rem 0px;
  &.open {
    display: block;
  }
}
