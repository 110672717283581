@import 'src/style/variables';

.floating-backdrop-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url("../../images/scopeBackground.png");
  background-size: cover;
  /*
  background-color: desaturate(darken($primary-color, 5), 30);
  .floating-box {
    transition: all 20s linear;
    position: absolute;
    background-color: saturate(lighten($primary-color, 10), 50);
  }
  */
}
