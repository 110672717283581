.navbar {
  align-items: center;
  background-color: var(--gray-800);
  display: flex;
  grid-column-gap: 1.5rem;
  grid-template-columns: repeat(3, auto);
  margin: 0;
  justify-content: space-between;
  min-height: 40px;
  padding: 0 1.25rem;
  position: relative;
  &.navbar-perspective {
    grid-template-columns: repeat(2, auto);
  }
  button { 
    transition:  0.15s background-color ease-in;
    background-color: var(--primary-color);
    border: 1px solid black;
    color: white;
    padding: 0.4rem;  
    &:focus, &:hover { 
      background-color: var(--secondary-color);
      color: white;
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .entry-type {
    &-subview { border-left: 0.5em solid var(--secondary-color); }
    &-tab { border-left: 0.5em solid var(--primary-color); }
  }
  .fuzzy-search {
    height: 30px;
    width: 19rem;
    margin: auto 0;
  }
  &-link {
    align-content: center;
    color: white;
    display: grid;
    font-size: 1.2rem;
    height: 100%;
    padding: 0 3rem;
    text-align: center;
    transition: background-color 0.15s ease-in;
    &:focus, &:hover{
      background-color: var(--secondary-color);
      color: white;
    }
    &[aria-current=true] {
      background-color: var(--primary-color);
      &:focus, &:hover {
        background-color: var(--primary-color-darker);
      }
    }
  }
  &-list {
    align-self: stretch;
    display: flex;
  }
  &-logo {
    max-width: 9.625rem;
    &-link {
      display: flex;
      justify-content: center;
      padding-right: 2rem;
    }
  }
  .navbar-right-container{
    display: flex;
    justify-content: flex-end;
  }
  .navbar-left-container{
    display: flex;
    height: 100%;
    align-items: center;
  }
  .search {
    &-box {
      display: none;
      padding: 0px 20px;
      z-index: 3;
    }
    &-description {
      color: #444444;
      font-size: 0.9em;
      font-weight: normal;
      white-space: normal;
    }
    &-input {
      background-color: var(--gray-600);
      background-image: url(../../images/icons/search.svg);
      background-position: left 0.75rem center;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      border: none;
      color: var(--gray-300);
      height: 100%;
      outline: none;
      padding: 0 0 0 3rem;
      width: 100%;
      &::placeholder { color: var(--gray-300); }
    }
    &-result {
      &-entry {
        background-color: var(--gray);
        border-top: 1px solid var(--gray-100);
        color: var(--gray-600);
        cursor: pointer;
        padding: 0.2em;
        position: relative;
        &:hover { background-color: var(--gray-200); }
      }
      &-holder {
        box-shadow: 0 1rem 2.5rem 0.2rem var(--black-10);
        box-sizing: border-box;
        font-size: 0.9em;
        font-weight: 700;
        overflow: auto;
        position: relative;
     
      }
    }
  }
  .user {
    &-menu {
      align-self: stretch;
      display: flex;
      justify-self: end;
      position: relative;
      & 
      &-button {
        border: 0;
        border-radius: 0;
        color: #000;
        font-weight: 400;
        padding: 0.1rem;
        &-logout {
          border-top: 1px solid var(--gray-300);
          padding: 1rem;
          &::before {
            -webkit-font-feature-settings: normal;
            -webkit-font-smoothing: antialiased;
            content: "\f08b";
            display: inline-block;
            font-family: "Font Awesome 5 Pro";
            font-feature-settings: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            line-height: 1;
            margin-right: 0.25rem;
            text-rendering: auto;
          }
        }
      }
      &-image {
        border: 0.2rem solid white;
        border-radius: 50%;
        box-shadow: 0.5rem 0.4rem 0.185rem var(--black-15);
        height: 6.5rem;
        width: 6.5rem;
      }
      &-item {
        display: grid;
        &:nth-of-type(1) {
          justify-self: center;
          padding: 1.4rem;
          .active-lang {
              button{
              background-color: var(--secondary-color);
              }

          }
        }
        &:nth-of-type(2) {
          color: white;
          border-top: 1px solid black;
          font-size: 0.875rem;
          font-weight: 600;
          padding: 0 1rem 1rem;
        }
        &-language {
          & span {
            text-align: center;
            font-size: 1.1rem;
          }
          background-color: #fff;
        }
      }
      &-list {
        background-color: var(--gray-300);
        border: 1px solid #d4d4d5;
        box-shadow: 0 2px 4px 0 rgba(34,36,38, 0.12), 0 2px 10px 0 rgba(34,36 ,38 ,0.15);
        display: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 1051;
        button {
          margin-top: 10px;
          border: none;
        }
        &.open { 
          display: grid;
        }
      }
      &-toggle {
        background-color: transparent;
        border: 0 none;
        display: grid;
        padding: 0 1rem;
      }
    }
  }
  .icon-user, .icon-question {
    fill: white;
    width: 1rem;
    height: 100%;
  }
}
@media (min-width: 1200px) {
  .navbar {
    grid-template-columns: repeat(4, auto);
    .search-box { display: flex; }
  }
}