@import 'src/style/variables';

.initialize-plan {
  flex: 1;
  padding: 2em;
  overflow: visible;
  & .init-hide {
    display: none;
  }
  &:focus{
    outline: none;
  }
  .ui.stretched.grid {
    .column {
      height: 100%;
      padding-left: 2em;
      padding-right: 2em;

      .initialize-plan-header {
        font-weight: normal;
        .initialize-plan-header-text{
          padding-top: 1rem;
            padding-left: 1rem;
          display: flex;
          flex-direction: column;
        }
      }
      .dropdown-group-label {
        padding: 1em;
      }
      .segment {
        border: 0;
        box-shadow: none;
      }
      .dropdown-group {
        padding-top: 0 !important;
      }
      .dropdown {
        height: 2em;
        font-size: 1.2em;
        background-color: #f3f1f5;
        border-radius: 0.4em;
        .chevron {
          float: right;
          color: $primary-color;
        }
        .menu {
          width: 100%;
        }
      }
    }
  }
}
