@import 'src/style/variables';

.titled-modal {
  $gray: #e6e6e6;
  .titled-modal-header {
    padding: 0.8em;
    text-transform: uppercase;
    background-color: $primary-color;
    font-weight: 600;
    color: white;
  }

  width: 90%;
  height: 80%;
  max-width: 1400px;
  max-height: 900px;
  transition: opacity 500ms ease-out, margin-top 500ms ease-out;
  &.titled-modal-entering {
    opacity: 0;
    margin-top: -5em;
  }
  &.titled-modal-entered {
    opacity: 1;
    margin-top: 0;
  }

  &.titled-modal-exiting,
  &.titled-modal-exited {
    opacity: 0;
    margin-top: 5em;
  }

  button {
    color: #ffffff;
    border: 0;
    border-radius: 0.2em;
    padding: 0.3em 2em 0.3em 2em;
    font-weight: 200;
    background-color: $primary-color;
    top: 14px !important;
    &:hover {
      background-color: darken($primary-color, 10);
    }
    &.cancel {
      $cancel-color: darken($gray, 35);
      // background-color: $cancel-color;
      &:hover {
        background-color: darken($cancel-color, 10);
      }
    }
    &.icon {
      padding: 0.3em;
      min-width: inherit;
    }
  }
}
.titled-modal-overlay {
  transition: opacity 500ms ease-out;
  &.titled-modal-overlay-entering,
  &.titled-modal-overlay-exiting,
  &.titled-modal-overlay-exited {
    opacity: 0;
  }
}
