@import 'src/style/variables';

.scope-select {
  display: flex;
  font-size: 14px;
  .scope-select-group-container {
    display: inline-block;
    flex: 1;
  }
  .scope-error {
    padding: 1rem;
    text-align: center;
  }
  .error-container.ui.segment {
    width: 20rem;
    position: absolute;
  }
  $white: #ffffff;

  .ui.button.accept-button {
    @media (max-width: 1000px) {
      width: 70%;
      padding: 0.4em !important;
      margin-top: 2em !important;
    }

    border-radius: 0;
    background-color: $white;
    color: $primary-color;
    position: relative;
    top: 28px;
    font-size: 1.58em;
    margin-left: 0.5em;
    padding: 0.2em !important;
    &.disabled {
      cursor: not-allowed;
      background-color: #c7c7c7;
      pointer-events: auto !important;
    }
  }
  .ui.button.accept-button.multi {
    font-size: 2em;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation-delay: 500ms;
  animation: fadeIn 2s;
  .scope-select-dimension-group {
    vertical-align: top;
    width: 100%;
    max-width: 20em;
    display: inline-block;
    margin-left: 1em;
    text-align: left;
    transition: width 400ms ease-in;

    &.hide-workflow {
      width: 0;
      overflow: hidden;
    }
    .season-status {
      display: block;
      font-size: 0.8em;
      line-height: 2em;
      color: $white;
      padding-left: 1em;
      .season-icon {
        padding-right: 0.4em;
      }
    }

    .ui.dropdown {
      &.disabled {
        opacity: 0.5;
        .chevron {
          display: none;
        }
      }
      overflow: hidden;
      &.active {
        overflow: visible;
      }
      .menu {
        border-radius: 0 0 0.4em 0.4em;
        border: none;
        transition: opacity 200ms ease-in, transform 100ms ease-in;
        transform: scale(0.7);
        display: block;
        opacity: 0;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0px 8px 10px 1px rgba(0, 0, 0, 0.14),
          0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        &.visible {
          transform: scale(1);
          opacity: 1;
        }

        > .item {
          padding: 1em !important;
          font-size: 0.8em;
          &.active {
            font-weight: normal;
            color: $white;
            background-color: $primary-color;
          }
        }
      }
    }

    .scope-select-dropdown-level {
      font-size: 1em;
      padding: 0.3em;
      color: $white;
      .chevron {
        padding: 0.4em;
      }
      &.disabled {
        background-color: initial;
        cursor: not-allowed;
        pointer-events: auto !important;
      }
    }
    .dropdown.multi {
      min-height: 38.61px;
      padding-top: 9px;
      padding-left: 14px;
    }
    .dropdown.multiple.multi {
      padding: 0.4em;
    }
    .scope-select-dropdown-member {
      background-color: desaturate(lighten($primary-color, 15), 30);
      padding: 0.4em;
      font-weight: lighter;
      color: $white;
      height: auto;
      .chevron.single {
        float: right;
        padding-top: 4px;
        margin-right: 5px;
      }
      .chevron.multi {
        position: absolute;
        top: 12px;
        right: 10px;
      }
      &.disabled {
        cursor: not-allowed;
        background-color: #c7c7c7;
        pointer-events: auto !important;
      }
      .label {
        background-color: #0A827C;
        color: white;
      }
    }
  }
}
