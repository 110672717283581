@import "../src/style/variables.scss";
@import "../src/style/custom-properties";
html,
body,
body > div {
}
body,
html {
  overscroll-behavior: none;
  font-size: 12px;
  overflow: hidden;
  i.icon {
    font-family: "Font Awesome 5 Pro", monospace;
  }
  margin: 0;
  font-family: $font-family
}
